import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";

import LoginForm from "./components/index";
import ForgetPasswordForm from "./components/ForgotPasswordForm";

import withTracker from './components/shared/withTracker';

class App extends React.Component {


	render() {
		return (

			<Router basename={process.env.REACT_APP_PUBLIC_URL}>
				<div className="tcp-login">

					<Route
						path="/enroller.php"
						exact
						component={withTracker(LoginForm)}
					/>

					<Route
						path="/enroller"
						exact
						component={withTracker(LoginForm)}
					/>

					<Route
						path="/forgot-password"
						exact
						component={withTracker(ForgetPasswordForm)}
					/>
					<Route
						path="/login.php"
						exact
						component={withTracker(LoginForm)}
					/>

					<Route
						path="/login"
						exact
						component={withTracker(LoginForm)}
					/>
				</div>
			</Router>
		);
	}
}

export default App;

