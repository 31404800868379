import React, {Component,} from "react";
import * as GoogleAnalytics from "react-ga";

GoogleAnalytics.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

const withTracker = (WrappedComponent, options = {}, props = {}) => {
	const trackPage = page => {
		GoogleAnalytics.set({
			page,
			...options,
		});
		GoogleAnalytics.pageview(page);
	};

	// eslint-disable-next-line
	const HOC = class extends Component {
		componentDidMount() {
			// eslint-disable-next-line
			let basePath;
			if(process && process.env){
				if(typeof process.env.REACT_APP_PUBLIC_URL === 'string'){

					basePath = process.env.REACT_APP_PUBLIC_URL;
				}
				else if(process.env.PUBLIC_URL){
					basePath = process.env.PUBLIC_URL;

				}
			}

			let page;
			if(props.location){
				page = basePath + props.location.pathname + props.location.search;
			}
			else{
				page = basePath + this.props.location.pathname + this.props.location.search;
			}
			trackPage(page);
		}

		componentDidUpdate(prevProps) {
			let basePath;
			if(process && process.env){
				if(typeof process.env.REACT_APP_PUBLIC_URL === 'string'){
					basePath = process.env.REACT_APP_PUBLIC_URL;
				}
				else if(process.env.PUBLIC_URL){
					basePath = process.env.PUBLIC_URL;
				}
			}

			const currentPage = basePath + prevProps.location.pathname + prevProps.location.search;

			let nextPage;
			if(props.location){
				nextPage = basePath + props.location.pathname + props.location.search;
			}
			else{
				nextPage = basePath + this.props.location.pathname + this.props.location.search;
			}

			if(currentPage !== nextPage){
				trackPage(nextPage);
			}
		}

		render() {
			return <WrappedComponent {...this.props} {...props} />;
		}
	};

	return HOC;
};

export default withTracker;