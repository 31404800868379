import React from 'react';
import ReactDOM from 'react-dom';
import BasicOverlay from "./BasicOverlay";


const styles = {
	      dialog: {
		      position:        'absolute',
		      top:             0, bottom: 0, left: 0, right: 0,
		      marginLeft:      'auto',
		      marginRight:     'auto',
		      width:           '100%',
		      minWidth:        '300px',
		      maxWidth:        '600px',
		      border:          '1px solid #e5e5e5',
		      backgroundColor: 'white',
		      boxShadow:       '0 5px 15px rgba(0,0,0,.5)',
		      padding:         10,
		      //overflowY: 'auto',
		      verticalAlign:   'middle'
	      },
	      body:   {
		      marginTop: '1em'
	      }
      }
;


class ErrorDialog extends React.Component {

	constructor(props) {
		super(props);
		this.state  = {
			showModal: true
		};
		this.close  = this.close.bind(this);
		this.ok     = this.ok.bind(this);
		this.cancel = this.cancel.bind(this);
		this.toggle = this.toggle.bind(this);
		this.open   = this.open.bind(this);

	} // constructor()

	render() {
		const body = this.props.body;
		return (
			<BasicOverlay
				{...this.props.modal}
				dialogStyles={styles.dialog}
				showModal={this.state.showModal}
				onToggle={() => {
					if(this.state.showModal !== false){
						this.setState({showModal: false}, () => {
							this.close();
						});
					}
				}
				}
				showClose={false}
			>
				<div className="tf-dialog">
					<div className="tf-dialog-header">
						<img className="float-left tf-dialog-logo" src="https://cdn.theconversionpros.com/assets/images/logo.png" style={{maxHeight: '3em'}}
						     title="The Conversion Pros, Inc." alt="The Conversion Pros, Inc"/>
						<button onClick={this.cancel} className="float-right" style={{background: 'none', border: 'none'}}>
							<i className="fa fa-times fa-2x" aria-hidden="true" title="Close"/>
						</button>
					</div>
					<div className="clearfix"/>
					<div className="tf-dialog-body" style={styles.body}>
						<div className="tf-dialog-body-middle">
							{body}
						</div>
					</div>
					<div className="tf-dialog-footer">
						<button onClick={this.ok} className="btn btn-primary btn-block">{this.props.okLabel || 'OK'}</button>
					</div>
				</div>
			</BasicOverlay>
		);
	} // render()

	cancel(e) {
		this.close();
		if(typeof this.props.onCancel !== 'undefined'){
			this.props.onCancel(e);
		}
	}

	ok(e) {
		this.close();
		if(typeof this.props.onOK !== 'undefined'){
			this.props.onOK(e);
		}
	}

	toggle() {
		this.setState({showModal: !this.showModal}, () => {
			if(typeof this.props.onToggle !== 'undefined'){
				this.props.onToggle();
			}
		});
	}

	close() {
		if(this.state.showModal === false){
			// already set
			return;
		}
		this.setState({showModal: false}, () => {
			if(typeof this.props.onClose !== 'undefined'){
				this.props.onClose();
			}
		});

		if(this.props.reload){
			if(this.props.reload === true){
				window.location.reload();
			}
			else{
				window.location.href = this.props.reload;
			}
		}
	}

	open() {
		if(this.state.showModal === true){
			// already set
			return;
		}
		this.setState({showModal: true}, () => {
			if(typeof this.props.onOpen !== 'undefined'){
				this.props.onOpen();
			}
		});

	}

} // ErrorDialog


class AlertDialog extends React.Component {

	constructor(props) {
		super(props);
		this.state  = {
			showModal: true
		};
		this.close  = this.close.bind(this);
		this.ok     = this.ok.bind(this);
		this.cancel = this.cancel.bind(this);
		this.toggle = this.toggle.bind(this);
		this.open   = this.open.bind(this);

	} // constructor()

	render() {
		return (
			<BasicOverlay
				{...this.props.modal}
				dialogStyles={styles.dialog}
				showModal={this.state.showModal}
				onToggle={() => {
					if(this.state.showModal !== false){
						this.setState({showModal: false}, () => {
							this.close();
						});
					}
				}}
				showClose={false}
			>
				<div className="tf-dialog">
					<div className="tf-dialog-header">
						<img className="pull-left tf-dialog-logo" src="https://cdn.theconversionpros.com/assets/images/logo.png" style={{maxHeight: '3em'}}
						     title="The Conversion Pros, Inc." alt="The Conversion Pros, Inc"/>
						<button onClick={this.cancel} className="pull-right" style={{background: 'none', border: 'none'}}>
							<i className="fa fa-times fa-2x" aria-hidden="true" title="Close"/>
						</button>
					</div>
					<div className="clearfix"/>
					<div className="tf-dialog-body" style={styles.body}>
						<div className="tf-dialog-body-middle">
							{this.props.children}
						</div>
					</div>
					<div className="tf-dialog-footer">
						<button onClick={this.ok} className="btn btn-primary btn-block">{this.props.okLabel || 'OK'}</button>
					</div>
				</div>
			</BasicOverlay>
		);
	} // render()

	cancel(e) {
		this.close();
		if(typeof this.props.onCancel !== 'undefined'){
			this.props.onCancel(e);
		}
	}

	ok(e) {
		this.close();
		if(typeof this.props.onOK !== 'undefined'){
			this.props.onOK(e);
		}
	}

	toggle() {
		this.setState({showModal: !this.showModal}, () => {
			if(typeof this.props.onToggle !== 'undefined'){
				this.props.onToggle();
			}
		});
	}

	close() {
		if(this.state.showModal === false){
			// already set
			return;
		}
		this.setState({showModal: false}, () => {
			if(typeof this.props.onClose !== 'undefined'){
				this.props.onClose();
			}
		});

		if(this.props.reload){
			if(this.props.reload === true){
				window.location.reload();
			}
			else{
				window.location.href = this.props.reload;
			}
		}
	}

	open() {
		if(this.state.showModal === true){
			// already set
			return;
		}
		this.setState({showModal: true}, () => {
			if(typeof this.props.onOpen !== 'undefined'){
				this.props.onOpen();
			}
		});

	}

} // AlertDialog

const tf = {
	/**
	 * @see https://stackoverflow.com/questions/6659351/removing-all-script-tags-from-html-with-js-regular-expression?answertab=active
	 * @param stringOfHtml
	 * @returns {*} string
	 */
	stripScripts: (stringOfHtml) => {
		var div       = document.createElement('div');
		div.innerHTML = stringOfHtml;
		var scripts   = div.getElementsByTagName('script');
		var i         = scripts.length;
		while(i--){
			scripts[i].parentNode.removeChild(scripts[i]);
		}
		return div.innerHTML;
	},
	/**
	 * @link http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
	 * @returns string
	 */
	uuid:         () => {
		const lut = [];
		for(let i = 0; i < 256; i++){
			lut[i] = (i < 16 ? '0' : '') + (i).toString(16);
		}

		function e7() {
			const d0 = Math.random() * 0xffffffff | 0;
			const d1 = Math.random() * 0xffffffff | 0;
			const d2 = Math.random() * 0xffffffff | 0;
			const d3 = Math.random() * 0xffffffff | 0;
			return lut[d0 & 0xff] + lut[(d0 >> 8) & 0xff] + lut[(d0 >> 16) & 0xff] + lut[(d0 >> 24) & 0xff] + '-' +
				lut[d1 & 0xff] + lut[(d1 >> 8) & 0xff] + '-' + lut[((d1 >> 16) & 0x0f) | 0x40] + lut[(d1 >> 24) & 0xff] + '-' +
				lut[(d2 & 0x3f) | 0x80] + lut[(d2 >> 8) & 0xff] + '-' + lut[(d2 >> 16) & 0xff] + lut[(d2 >> 24) & 0xff] +
				lut[d3 & 0xff] + lut[(d3 >> 8) & 0xff] + lut[(d3 >> 16) & 0xff] + lut[(d3 >> 24) & 0xff];
		}

		return e7();
	},
	/**
	 * Get URL Parameter added decodeURIComponent()
	 * @link http://stackoverflow.com/questions/979975/how-to-get-the-value-from-the-url-parameter
	 * @param name
	 * @param url
	 * @returns {null}|string
	 */
	gup:          (name, url) => {
		if(!url) url = window.location.href;
		name          = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
		const regexS  = "[\\?&]" + name + "=([^&#]*)";
		const regex   = new RegExp(regexS);
		const results = regex.exec(url);
		return results === null ? null : decodeURIComponent(results[1]);
	},
	/**
	 * Handles standard error formats
	 * @param response object
	 * @param onClose function
	 */
	rpcError:     (response, onClose) => {
		let messages = [];
		if(response.error){
			let uuid      = tf.uuid();
			let mountNode = document.createElement("div");
			mountNode.id  = uuid;
			document.body.appendChild(mountNode);

			let close = () => {
				// clean the dom up
				ReactDOM.unmountComponentAtNode(mountNode);
				mountNode.remove();
				if(typeof onClose === 'function'){
					onClose();
				}
			};

			if(response.error.message){
				let message = (<div className="alert alert-info" role="alert">{response.error.message}</div>);
				ReactDOM.render(<ErrorDialog body={message} onClose={close}/>, mountNode);
			}
			else if(Object.prototype.toString.call(response.error) === '[object Array]'){
				for(let i in response.error){
					if(!response.error.hasOwnProperty(i)){
						continue;
					}
					if(response.error[i].message){
						messages.push(response.error[i].message);
					}
					else{
						messages.push(response.error[i]);
					}
				}
				if(messages.length > 0){
					let message = messages.map((msg) => {
						return (<div className="alert alert-info" role="alert">{msg}</div>)
					});
					ReactDOM.render(<ErrorDialog body={message} onClose={close}/>, mountNode);
				}
				else{
					// error with no message
				}
			}
			else{
				let reload  = response.reload || false;
				let message = (<div className="alert alert-info" role="alert">{response.error}</div>);
				ReactDOM.render(<ErrorDialog body={message} onClose={close} reload={reload}/>, mountNode);
			}
		}
		else{
			// unknown
		}
	},
	/**
	 * Handles standard error formats
	 * @param message string
	 * @param onClose function
	 */
	alert:        (message, onClose) => {
		let uuid      = tf.uuid();
		let mountNode = document.createElement("div");
		mountNode.id  = uuid;
		document.body.appendChild(mountNode);

		let close = () => {
			// clean the dom up
			ReactDOM.unmountComponentAtNode(mountNode);
			mountNode.remove();
			if(typeof onClose === 'function'){
				onClose();
			}
		};

		let content = null;
		if(typeof message === 'string'){
			content = <div dangerouslySetInnerHTML={{__html: message}}/>;
		}
		else{
			content = message;
		}
		ReactDOM.render(<AlertDialog onClose={close}>{content}</AlertDialog>, mountNode);
	},
	rpc:          (params) => {
		return new Promise((resolve, reject) => {

			if(!params.method){
				reject(new Error('method required'));
				return;
			}

			const defaults = {
				method: '',
				params: {},
				url:    'rpc/'
			};

			if(process && process.env){
				if(typeof process.env.REACT_APP_PUBLIC_URL === 'string'){
					defaults.url = process.env.REACT_APP_PUBLIC_URL + '/rpc/';
				}
				else if(process.env.PUBLIC_URL){
					defaults.url = process.env.PUBLIC_URL + '/rpc/';
				}
			}

			let options = Object.assign({}, defaults, params);

			fetch(options.url,
				{
					method:      "POST", // *GET, POST, PUT, DELETE, etc.
					mode:        "cors", // no-cors, cors, *same-origin
					cache:       "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
					credentials: "same-origin", // include, same-origin, *omit
					headers:     {
						"Content-Type": "application/json; charset=utf-8",
					},
					body:        JSON.stringify({method: options.method, params: options.params}),
				}
			).then((response) => response.json())
			.then((data) => {

				if(typeof data.result !== 'undefined'){
					resolve(data);
					return;
				}
				else if(typeof data.error !== 'undefined'){
					tf.rpcError(data);
				}
				else{
					// unknown protocol
				}

				reject(data);
			}).catch((e) => {
				// connection failure
				reject(e);
				tf.rpcError({error: 'Connection Failure'});
			});
		});

	}
};


export default tf;
export {ErrorDialog};
