import React, {Component} from 'react';
import ReactCodeInput from 'react-code-input';
import classnames from 'classnames';
import {checkAccountExists, faceBookLogin, login, twoFactorLogin} from '../service/';
import {ReactComponent as Loading} from '../tcp-loading.svg';
import '../css/style.css';
import {Link} from 'react-router-dom'
import tf from './tf';

const grecaptcha = window.grecaptcha;

class LoginForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			memberId: null,
			message: '',
			password: '',
			identifier: '',
			accessToken: '',
			twoFactorAuth: false,
			identifierType: '',
			requestTwoFactorAuthPin: false,
			captcha: '',
			errors: {},
		}
	}

	componentDidMount() {
		this.getCaptchaJSON();
	}

	getCaptchaJSON = () => {
		if(grecaptcha){
			this.setState({waitingOnCaptcha: true,});
			grecaptcha.ready(async () => {
				try{
					const token = await grecaptcha.execute('6Lfk_ZwUAAAAAPxrJyXeCMzJBuvT9y-wEOSfRUaQ', {action: 'login'});
					await this.setState({captcha: token, loading: false, waitingOnCaptcha: false});
				} catch(e){
					this.setState({loading: false, waitingOnCaptcha: false});
				}
			})
		}
	};

	close = () => this.setState({requestTwoFactorAuthPin: false});

	onChange = (e) => this.setState({[e.target.name]: e.target.value.trim(), errors: {}});

	handleTwoFactorAuth = (value) => {
		if(!isNaN(parseFloat(value)) && isFinite(value) && value.length === 6){
			this.setState({
				pin: value,
				isLoading: true
			}, async () => {
				try{
					let response = await twoFactorLogin(this.state);
					if(response && response.result && response.result.redirects){
						this.setState({
							isLoading: false,
						}, () => {
							let loginRedirectUrl = tf.gup('loginRedirectUrl');
							if(loginRedirectUrl){
								window.location = loginRedirectUrl;
							}
							else{
								window.location = response.result.redirects[0] || "/portal/";
							}
						});
					}
					else{
						this.setState({
							isLoading: false,
							message: response.result.message || response.result,
							requestTwoFactorAuthPin: false,
						});
					}
				} catch(e){
					this.setState({
						memberId: null,
						message: '',
						password: '',
						identifier: this.state.identifierType === 'facebook' ? '' : this.state.identifier,
						twoFactorAuth: false,
						identifierType: '',
						requestTwoFactorAuthPin: false
					});
				}


			})
		}
		else{
			//do nothing
		}
	};

	handleUpdateIdentifier = () => {
		this.setState({
			memberId: null,
			message: '',
			password: '',
			identifier: '',
			twoFactorAuth: false,
			identifierType: '',
			requestTwoFactorAuthPin: false
		});
	};

	getAccountAuthMethod = async () => {
		let params = {};
		let identifier = this.state.identifier;
		if(identifier == null || identifier === ''){
			return;
		}
		if(identifier.indexOf('@') !== -1){
			params.identifierType = "email";

		}
		else{
			params.identifierType = "username";
		}
		this.setState({
			identifierType: params.identifierType
		});
		params.identifier = identifier;
		params.password = this.state.password;
		try{
			let response = await checkAccountExists(params);
			if(response && response.result){
				return response.result;
			}
			else{
				this.setState({
					memberId: null,
					message: response.error ? response.error : ''
				});

			}
		} catch(e){
			console.log(e);
			this.setState({
				memberId: null,
				message: e.error ? e.error : ''
			});

		}
	};

	handleBackToLoginForm = (e) => {
		e.preventDefault();
		this.setState({
			requestTwoFactorAuthPin: false,
			message: ''
		})
	};

	handleFacebookLogin = async (fbResponse) => {
		if(fbResponse && fbResponse.id){
			let params = {};
			params.identifierType = 'facebook';
			params.identifier = fbResponse.id;
			params.accessToken = fbResponse.accessToken;
			try{
				let response = await checkAccountExists(params);
				if(response && response.result && response.result.twoFactorAuth !== 'Off'){
					this.setState({
						memberId: response.result.memberId,
						twoFactorAuth: response.result.twoFactorAuth.toLowerCase() !== 'off',
						requestTwoFactorAuthPin: response.result.twoFactorAuth.toLowerCase() !== 'off',
						identifierType: params.identifierType,
						identifier: params.identifier,
						accessToken: params.accessToken,
						message: ""
					})
				}
				else{
					try{
						let response = await faceBookLogin(params);
						if(response && response.result){
							if(response.result.redirects){
								let loginRedirectUrl = tf.gup('loginRedirectUrl');
								if(loginRedirectUrl){
									window.location = loginRedirectUrl;
								}
								else{
									window.location = response.result.redirects[0] || "/portal/";
								}
							}
							else{
								this.setState({
									identifier: fbResponse.id,
									identifierType: params.type,
									twoFactorAuth: true,
									requestTwoFactorAuthPin: true,
									message: ""
								})
							}
						}
						else{
							this.setState({
								message: response.error ? response.error : '',
								memberId: null,
							});
						}
					} catch(e){
						// console.log(e);
					}
				}
			} catch(e){
				this.setState({
					memberId: null,
					message: '',
					password: '',
					identifier: '',
					twoFactorAuth: false,
					identifierType: '',
					requestTwoFactorAuthPin: false
				});
			}
		}
	};

	handleFormLogin = async (e) => {
		e.preventDefault();
		let isError = false;
		if(this.state.identifier === ''){
			isError = true;
			this.setState(prevState => ({errors: {...prevState.errors, identifier: 'Username or email cannot be empty.'}}));
		}

		if(this.state.password === ''){
			isError = true;
			this.setState(prevState => ({errors: {...prevState.errors, password: 'Password cannot be empty.'}}));
		}

		if(this.state.waitingOnCaptcha){
			// still waiting for google recaptcha or already processing login
			return;
		}

		if(!isError){
			try{
				this.setState({loading: true});
				let response = await this.getAccountAuthMethod();
				if(response.twoFactorAuth !== 'Off'){
					this.setState({
						memberId: response.memberId,
						twoFactorAuth: response.twoFactorAuth.toLowerCase() !== 'off',
						requestTwoFactorAuthPin: response.twoFactorAuth.toLowerCase() !== 'off',
						identifierType: this.state.identifierType,
						message: ""
					})
				}
				else{
					try{
						let response = await login(this.state);

						if(response){
							this.setState({
								loading: false
							});
							if(response.result && response.result.redirects){
								let loginRedirectUrl = tf.gup('loginRedirectUrl');
								if(loginRedirectUrl){
									window.location = loginRedirectUrl;
								}
								else{
									window.location = response.result.redirects[0] || "/portal/";
								}
							}
						}
						else{
							this.setState({
								loading: false,
								message: response.error ? response.error : ''
							});
						}
					} catch(response){
						this.setState({
							loading: false,
							message: response.error ? response.error : ''
						});
					}

				}
			} catch(error){
				this.setState({
					loading: false,
					waitingOnCaptcha: false
				});
			}
		}
	};

	render() {
		const {errors, requestTwoFactorAuthPin, isValidPin, memberId, identifier, password, loading, waitingOnCaptcha} = this.state;
		if(requestTwoFactorAuthPin){
			return (
				<div className={"login-container text-center"}>
					<h1>Two Factor Authentication Code</h1>
					<ReactCodeInput
						type='number'
						fields={6}
						autoFocus
						isValid={isValidPin}
						onChange={this.handleTwoFactorAuth}
					/>
					<hr/>
					<a href={"#"} onClick={this.handleBackToLoginForm}>Back</a>
				</div>
			);
		}

		return (
			<div className="login-container">
				<div className='login-header text-center'>
					LOG IN TO YOUR ACCOUNT
				</div>
				<div className='sign-up-free text-center'>
					Need a TCP account? <a href='https://theconversionpros.com/join-trial'>Sign up for free.</a>
				</div>
				<form onSubmit={this.handleFormLogin}>
					<div style={{position: 'relative'}}>
						<div className={classnames({"row tcp-form-group": true, 'is-invalid': !!errors.identifier})}>
							<div className='col-auto'><label>USERNAME:</label></div>
							<div className='col'>
								<input name="identifier"
								       onChange={this.onChange}
								       type="text"
								       className="form-control"
								       disabled={memberId}
								       placeholder="Username or Email"
								       aria-label="Username or Email"
								       aria-describedby="basic-addon1"
								       value={identifier}
								       autoFocus
								/>
							</div>
						</div>
						<div className='invalid-message'>
							<small>{errors.identifier}</small>
						</div>
					</div>

					<div style={{position: 'relative'}}>
						<div className={classnames({"row tcp-form-group": true, 'is-invalid': !!errors.password})}>
							<div className='col-auto'><label>PASSWORD:</label></div>
							<div className='col'>
								<input
									onChange={this.onChange}
									name="password"
									type="password"
									className="form-control"
									placeholder="Password"
									value={password}
								/>
							</div>
						</div>
						<div className='invalid-message'>
							<small>{errors.password}</small>
						</div>
					</div>

					<div className='text-center'>
						< button className='btn blank-blue' type="submit" disabled={waitingOnCaptcha}>
							{loading && <span><Loading style={{height: 26}}/></span>} <span>Login</span>
						</button>
						{waitingOnCaptcha ?
							<p style={{paddingBottom: '0px'}}>
								<span style={{paddingRight: 20}}><Loading style={{width: 24}}/></span> <span>Verifying that you are a human.</span>
							</p>
							:
							<p style={{height: '24px', paddingBottom: '0px'}}/>
						}
					</div>
					<div className="clearfix"/>
				</form>
				<div className="text-center forgot-password">
					<Link to='/forgot-password'>Forgot Password</Link>
				</div>
			</div>
		);
	}
}

LoginForm.propTypes = {};

export default LoginForm;
