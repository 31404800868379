import tf from '../components/tf';

const endpoint = "rpc/";

//this call will return if the username/email exist in our system
//if yes, then it will return if the user enabled two factor auth
const checkAccountExists = (data) => {
	let params = {
		identifier: data.identifier,
		identifierType: data.identifierType, //email, username, facebook account id
		password: data.password //email, username, facebook account id
	};
	return tf.rpc({
		'method': 'checkAccountExists',
		params
	});
};


const tcpLogin = (cred) => {
	let params = {
		pin: cred.pin ? cred.pin : null,
		identifier: cred.identifier,
		identifierType: cred.identifierType,
		password: cred.password ? cred.password : null,
		accessToken: cred.accessToken ? cred.accessToken : null,
	};
	return tf.rpc({
		'method': 'tcpLogin',
		params
	});
};

//this call will return if the username/email exist in our system
//if yes, then it will return if the user enabled two factor auth
const faceBookLogin = (data) => {
	let params = {
		identifier: data.identifier,
		identifierType: data.identifierType, //email, username, facebook account id
		accessToken: data.accessToken
	};
	return tf.rpc({
		'method': 'login',
		params
	});
};

//if two factor auth is not enabled use login directly
//the login rpc will double check if two factor login enabled or not
//if enabled reject rpc and use twoFactorLogin method
const login = (cred) => {
	let params = {
		identifier: cred.identifier,
		identifierType: cred.identifierType,
		password: cred.password,
		captcha: cred.captcha
	};

	let response = tf.rpc({
		'method': 'login',
		params
	});
	console.log(response);
	return response;
};


const twoFactorLogin = async (data) => {
	let params = {
		pin: data.pin,
		identifier: data.identifier,
		identifierType: data.identifierType,
		password: data.password,
		accessToken: data.accessToken
	};
	return tf.rpc({
		'method': 'login',
		params
	});
};

const sendRecoveryPasswordEmail = async (email, phone, viaMethod) => {
	let params = {
		email: email,
		phone: phone,
		viaMethod: viaMethod
	};
	return tf.rpc({
		method: 'sendPasswordRecoveryLink',
		params
	});
};


export {checkAccountExists, login, twoFactorLogin, sendRecoveryPasswordEmail, faceBookLogin};
