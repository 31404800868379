import React, {Component} from 'react';
import PhoneInput from 'react-phone-number-input';
import {ReactComponent as Loading} from '../tcp-loading.svg';
import classnames from 'classnames';
import tf from './shared/tf';
import '../App.css';

const grecaptcha = window.grecaptcha;

class ForgotPasswordForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			message: '',
			email: '',
			phone: '',
			showMessage: false,
			viaMethod: 'email',
			waitingOnCaptcha: false,
			captcha: '',
		}
	}

	componentDidMount() {
		this.getCaptchaJSON();
	}

	getCaptchaJSON = () => {
		if(grecaptcha){
			this.setState({waitingOnCaptcha: true,});
			grecaptcha.ready(async () => {
				try{
					const token = await grecaptcha.execute('6Lfk_ZwUAAAAAPxrJyXeCMzJBuvT9y-wEOSfRUaQ', {action: 'restPassword'});
					await this.setState({captcha: token, waitingOnCaptcha: false});
				} catch(e){
					this.setState({waitingOnCaptcha: false});
				}
			})
		}
	};

	handleChange = e => this.setState({[e.target.name]: e.target.value});

	handleBack = () => this.setState({showMessage: false});

	handleSetState = state => this.setState({...state});

	render() {
		const {email, phone, showMessage, viaMethod, message, waitingOnCaptcha, captcha} = this.state;
		return (
			<div className="forgot-password-container">
				{!showMessage && <Header/>}
				{!showMessage && <ViaMethods value={viaMethod} onChange={this.handleChange}/>}
				{!showMessage && viaMethod === 'email' &&
				<Email
					value={email}
					captcha={captcha}
					setParentState={this.handleSetState}
					waitingOnCaptcha={waitingOnCaptcha}
				/>}
				{!showMessage && viaMethod === 'phone' &&
				<Phone
					value={phone}
					setParentState={this.handleSetState}
					captcha={captcha}
					waitingOnCaptcha={waitingOnCaptcha}
				/>}
				{showMessage && <Message message={message} onBack={this.handleBack}/>}
				<Footer/>
			</div>
		);
	}
}

export default ForgotPasswordForm;


const Header = () => (
	<div className='text-center m-4 m-md-0'>
		<p style={{fontSize: '1.5rem', fontWeight: '500'}}>Please provide your email or phone number to reset your password.</p>
	</div>
);

const ViaMethods = ({value, onChange}) => (
	<div className='d-flex justify-content-center mb-3'>
		<div className="custom-control custom-radio custom-control-inline">
			<input type="radio"
			       value='email'
			       id="via-email"
			       name="viaMethod"
			       checked={value === 'email'}
			       className="custom-control-input"
			       onChange={onChange}
			/>
			<label className="custom-control-label" htmlFor="via-email">Email</label>
		</div>
		<div className="custom-control custom-radio custom-control-inline">
			<input type="radio"
			       value='phone'
			       id="via-phone"
			       name="viaMethod"
			       checked={value === 'phone'}
			       className="custom-control-input"
			       onChange={onChange}
			/>
			<label className="custom-control-label" htmlFor="via-phone">Phone</label>
		</div>
	</div>
);

class Email extends Component {

	state = {
		value: '',
		loading: false,
		error: null,
	};

	onChange = e => this.setState({value: e.target.value, error: null});

	handleSubmit = async (e) => {
		e.preventDefault();
		if(this.state.value === ''){
			this.setState({error: 'Email cannot be empty.'});
		}
		else{
			try{
				this.setState({loading: true});
				const response = await tf.rpc({method: 'resetPassword', params: {email: this.state.value, captcha: this.props.captcha}}).promise;
				if(response.result){
					this.setState({loading: false});
					if(typeof this.props.setParentState === 'function'){
						this.props.setParentState({showMessage: true, message: response.result});
					}
				}
				else{
					this.setState({loading: false});
				}
			} catch(e){
				this.setState({loading: false});
				console.log(e);
			}
		}

	};

	render() {
		const {value, loading, error} = this.state;
		return <form onSubmit={this.handleSubmit} style={{position: 'relative'}}>
			<div className={classnames({"row tcp-form-group": true, 'is-invalid': !!error})}>
				<div className="col-sm-auto col-12" style={{width: '130px'}}>
					<label htmlFor="email">Email:</label>
				</div>
				<div className="col">
					<input name="email"
					       onChange={this.onChange}
					       type="text"
					       className='form-control'
					       id="email"
					       value={value}
					/>
				</div>
			</div>
			<div className='invalid-message'>
				<small>{error}</small>
			</div>
			<div className='text-center'>
				<button type="submit" className="btn blank-blue" style={{display: 'block', margin: '1rem auto'}} disabled={loading || this.props.waitingOnCaptcha}>
					{loading && <span><Loading style={{height: 26}}/></span>} <span>Submit</span>
				</button>
				{this.props.waitingOnCaptcha ?
					<p style={{paddingBottom: '0px'}}>
						<span style={{paddingRight: 20}}><Loading style={{width: 24}}/></span> <span>Verifying that you are a human.</span>
					</p>
					:
					<p style={{height: '24px', paddingBottom: '0px'}}/>
				}
			</div>
		</form>
	}
}

class Phone extends Component {

	state = {
		value: '',
		loading: false,
		error: null,
	};

	onChange = value => this.setState({value, error: null});

	handleSubmit = async (e) => {
		e.preventDefault();
		if(this.state.value === ''){
			this.setState({error: 'Phone number cannot be empty.'});
			return;
		}
		try{
			this.setState({loading: true});
			const response = await tf.rpc({method: 'resetPassword', params: {phone: this.state.value}}).promise;
			if(response.result){
				this.setState({loading: false});
				if(typeof this.props.setParentState === 'function'){
					this.props.setParentState({showMessage: true, message: response.result});
				}
			}
			else{
				this.setState({loading: false});
			}
		} catch(e){
			this.setState({loading: false});
			console.log(e);
		}
	};

	render() {
		const {value, loading, error} = this.state;
		return <form onSubmit={this.handleSubmit} style={{position: 'relative'}}>
			<div className={classnames({"row tcp-form-group phone-input": true, 'is-invalid': !!error})}>
				<div className="col-sm-auto col-12" style={{width: '80px'}}>
					<label htmlFor="phone">Phone:</label>
				</div>
				<div className="col">
					<PhoneInput
						country="US"
						international={false}
						id="phone"
						value={value}
						onChange={this.onChange}
					/>
				</div>
			</div>
			<div className='invalid-message'>
				<small>{error}</small>
			</div>
			<div className='text-center'>
				<button type="submit" className="btn blank-blue" style={{display: 'block', margin: '1rem auto'}} disabled={loading || this.props.waitingOnCaptcha}>
					{loading && <span><Loading style={{height: 26}}/></span>} <span>Submit</span>
				</button>
				{this.props.waitingOnCaptcha ?
					<p style={{paddingBottom: '0px'}}>
						<span style={{paddingRight: 20}}><Loading style={{width: 24}}/></span> <span>Verifying that you are a human.</span>
					</p>
					:
					<p style={{height: '24px', paddingBottom: '0px'}}/>
				}
			</div>
		</form>
	}
}

const Message = ({message, onBack}) => (
	<div className='text-center'>
		<p style={{fontSize: '1.5rem', fontWeight: '500'}}>{message}</p>
		<button onClick={onBack} className='btn btn-link'>
			Back
		</button>
	</div>
);

const Footer = () => (
	<div className="text-center contact-help">
		<p>If you need immediate help, or do not get your forgot password email please click
			<a href="mailto:support@theconversionpros.com?subject=theconversionpros.com"> here</a>.
		</p>
	</div>
);
