import React from "react";
import BasicOverlay from "../BasicOverlay";

const styles = {
	dialog: {
		position: 'absolute',
		top: 0, bottom: 0, left: 0, right: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
		width: '100%',
		minWidth: '300px',
		maxWidth: '600px',
		border: '1px solid #e5e5e5',
		backgroundColor: 'white',
		boxShadow: '0 5px 15px rgba(0,0,0,.5)',
		padding: 10,
		//overflowY: 'auto',
		verticalAlign: 'middle'
	},
	body: {
		marginTop: '1em'
	}
};

class AlertDialog extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			showModal: true
		};
	} // constructor()

	render() {
		return (
			<BasicOverlay
				{...this.props.modal}
				dialogStyles={styles.dialog}
				showModal={this.state.showModal}
				onToggle={() => {
					if(this.state.showModal !== false){
						this.setState({showModal: false}, () => {
							this.close();
						});
					}
				}}
				showClose={false}
			>
				<div className="tf-dialog">
					<div className="tf-dialog-header">
						<img className="float-left tf-dialog-logo" src="https://cdn.theconversionpros.com/assets/images/logo-short.png" style={{maxHeight: '3em'}}
						     title="The Conversion Pros, Inc." alt="The Conversion Pros, Inc"/>
						<button onClick={this.cancel} className="float-right" style={{background: 'none', border: 'none', cursor: 'pointer'}}>
							<i className="fa fa-times fa-2x" aria-hidden="true" title="Close"/>
						</button>
						<div className='text-center p-2'><label>{this.props.title}</label></div>
					</div>
					<div className="clearfix"/>
					<div className="tf-dialog-body" style={styles.body}>
						<div className="tf-dialog-body-middle">
							{this.props.children}
						</div>
					</div>
					<div className="tf-dialog-footer">
						<button onClick={this.ok} className="btn btn-primary btn-block">{this.props.okLabel || 'OK'}</button>
					</div>
				</div>
			</BasicOverlay>
		);
	} // render()

	cancel = (e) => {
		this.close();
		if(typeof this.props.onCancel !== 'undefined'){
			this.props.onCancel(e);
		}
	};

	ok = (e) => {
		this.close();
		if(typeof this.props.onOK !== 'undefined'){
			this.props.onOK(e);
		}
	};

	toggle = () => {
		this.setState({showModal: !this.showModal}, () => {
			if(typeof this.props.onToggle !== 'undefined'){
				this.props.onToggle();
			}
		});
	};

	close = () => {
		if(this.state.showModal === false){
			// already set
			return;
		}
		this.setState({showModal: false}, () => {
			if(typeof this.props.onClose !== 'undefined'){
				this.props.onClose();
			}
		});
	};

	open = () => {
		if(this.state.showModal === true){
			// already set
			return;
		}
		this.setState({showModal: true}, () => {
			if(typeof this.props.onOpen !== 'undefined'){
				this.props.onOpen();
			}
		});

	}

} // AlertDialog

export default AlertDialog